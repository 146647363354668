import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Loader from '../Shared/Global/Loader'

const ProfileSettings = lazy(() => import('../Shared/ProfileSettings'))
const Dashboard = lazy(() => import('../Shared/Global/Dashboard'))
const StudentCourse = lazy(() => import('./StudentCourse'))
const Studio = lazy(() => import('../Studio'))
const HelpCenter = lazy(() => import('../Shared/HelpCenter'))
const MidiTest = lazy(() => import('../Shared/Global/MidiTest'))

export default function Student() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <MidiTest />
        <Switch>
          <Route exact path="/course/:courseID" component={StudentCourse} />
          <Route
            exact
            path="/course/:courseID/assignment/:assignmentID"
            component={Studio}
          />
          <Route exact path="/help" component={HelpCenter} />
          <Route exact path="/profile-settings" component={ProfileSettings} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </Router>
    </Suspense>
  )
}
