import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { BsCheck } from 'react-icons/bs'

export default function VerificationMessage({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <BsCheck className="mt-n1 mr-2 text-success" />
          Thank You
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>Please check your email to verify your account</Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}
