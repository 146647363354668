import React from 'react'
import { Modal, Button, Form, FloatingLabel } from 'react-bootstrap'

import { sendResetPasswordEmail } from '../../services/apiCalls'

export default function SendResetPassword({
  show,
  handleClose,
  prefilledEmail,
}) {
  const handleSubmit = async e => {
    e.stopPropagation()
    e.preventDefault()
    const data = prefilledEmail
      ? prefilledEmail
      : e.currentTarget.elements['email'].value

    await sendResetPasswordEmail(data)
    return handleClose()
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Reset Password</Modal.Title>
      </Modal.Header>
      {!prefilledEmail ? (
        <>
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <FloatingLabel label="Email address" className="mb-2">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  name="email"
                  required
                />
              </FloatingLabel>
              <small>
                After clicking on <i>Send me a reset-password email</i>,
                <br />
                please check your email and follow the link we have sent.
              </small>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit">Send me a reset-password email</Button>
            </Modal.Footer>
          </Form>
        </>
      ) : (
        <>
          <Modal.Body>
            <p>
              To reset your passwod, we will send a reset-password link to{' '}
              <strong>{prefilledEmail}</strong> <br /> After clicking on{' '}
              <i>Send me a reset-password email</i>, please check your email and
              follow the link we have sent.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleSubmit}>
              Send me a reset-password email
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}
