import axios from 'axios'
const api = axios.create({ withCredentials: true })
const url = process.env.REACT_APP_API + '/user'

const SUBSCRIPTION = (
  state = { products: [], plan: {}, status: true },
  action
) => {
  switch (action.type) {
    case 'SET_PRODUCTS':
      return { ...state, products: action.payload }
    case 'FETCH_PLAN':
      return {
        ...state,
        plan: action.payload.data,
        status: action.payload.status,
        countDown: action.payload.countDown,
      }
    default:
      return state
  }
}

export const setProducts = async dispatch => {
  const { data } = await api.get(`${url}/subscription-plans`)

  return dispatch({
    type: 'SET_PRODUCTS',
    payload: data,
  })
}

// export const fetchSubscriptionPlan = async (dispatch, subid) => {
//   const { data } = await getSubscription(subid)

//   const lastPayment = new Date(data.billing_info.last_payment.time)

//   let status = false
//   let countDown = false
//   if (data.status === 'ACTIVE') status = true

//   if (
//     data.details.name.slice(0, 7) === 'monthly' &&
//     new Date(lastPayment.setDate(lastPayment.getDate() + 30)) > new Date()
//   ) {
//     status = true
//     if (data.status !== 'ACTIVE') countDown = true
//   }

//   return dispatch({
//     type: 'FETCH_PLAN',
//     payload: { data, status, countDown },
//   })
// }

export default SUBSCRIPTION
