import claim from './claims'

const [LOW, HIGH] = [33, 63]

export const checkBassLeap = (index, cBass, pBass) => {
  const d = Math.abs(cBass - pBass)

  if (cBass > HIGH || cBass < LOW)
    return claim('bass-out-of-range', index, [0], cBass > HIGH ? 'high' : 'low')

  if (d > 12) return claim('bass-leap', index, [0])

  if (pBass < 57 && cBass < pBass && [10, 11].some(x => x === d))
    return claim('bass-leap', index, [0])

  if (pBass > 43 && cBass > pBass && [10, 11].some(x => x === d))
    return claim('bass-leap', index, [0])
}

export const setBassScaler = note => {
  if (note > HIGH) return -12
  if (note < LOW) return 12
  return 0
}
