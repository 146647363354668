const INSTRUCTOR = (state = [], action) => {
  switch (action.type) {
    case 'ADD_CLAIMS':
      return [...state, ...action.payload]
    case 'CLEAR_CLAIMS':
      return []
    default:
      return state
  }
}

export default INSTRUCTOR

export const addInstructorClaims = claims => ({
  type: 'ADD_CLAIMS',
  payload: claims,
})

export const clearInstructor = () => ({
  type: 'CLEAR_CLAIMS',
})
