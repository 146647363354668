const init = {
  currentNotes: [],
  lastPlayed: [],
  data: [],
  step: 0,
  connected: false,
  browserMessage: [],
  devices: null,
  audioContext: null,
  storeLogs: false,
  logs: [],
  gate: true,
}

const MIDI = (state = init, action) => {
  const midi = { ...state }
  switch (action.type) {
    case 'MIDI_SUPPORT':
      return {
        ...midi,
        browserMessage: action.payload
          ? ['MIDI is supported by this browser', true]
          : ['MIDI is not supported by this browser', false],
      }

    case 'ADD_MIDI_DEVICE':
      midi.devices = [
        ...(midi.devices ? midi.devices : []),
        action.payload,
      ].filter(x => x)
      return { ...midi }

    case 'CONNECT':
      return { ...midi, connected: true }

    case 'SET_CONTEXT':
      return { ...midi, audioContext: action.payload }

    case 'UPDATE_NOTE':
      if (
        midi.currentNotes.some(note => note === action.payload.note) &&
        !action.payload.isOn
      )
        return {
          ...midi,
          currentNotes: midi.currentNotes.filter(
            n => n !== action.payload.note
          ),
        }

      if (action.payload.isOn)
        return {
          ...midi,
          currentNotes: [...midi.currentNotes, action.payload.note].sort(),
          lastPlayed: [...midi.lastPlayed, action.payload.note].sort(),
        }

      return { ...midi }

    case 'STEP_FORWARD':
      midi.data.push(state.lastPlayed)
      midi.lastPlayed = []
      midi.step += 1
      return { ...midi }

    case 'RESET_MIDI_DATA':
      return { ...midi, currentNotes: [], lastPlayed: [], data: [], step: 0 }

    case 'CLEAR_CURRENT_NOTES':
      return { ...midi, currentNotes: [] }

    case 'SET_MIDI_LOGS':
      return { ...midi, storeLogs: action.payload }

    case 'ADD_MIDI_LOG':
      return { ...midi, logs: [...midi.logs, action.payload] }

    case 'CLEAR_MIDI_LOGS':
      return { ...midi, logs: [] }

    case 'OPEN_MIDI_GATE':
      return { ...midi, gate: false }

    case 'CLOSE_MIDI_GATE':
      return { ...midi, gate: true }

    default:
      return state
  }
}

export const updateNote = (note, isOn) => ({
  type: 'UPDATE_NOTE',
  payload: {
    note,
    isOn,
  },
})

export const setCurrentNotes = current => ({
  type: 'SET_CURRENT_NOTES',
  payload: current,
})

export const stepForward = () => ({
  type: 'STEP_FORWARD',
})

export const resetMidiData = () => ({
  type: 'RESET_MIDI_DATA',
})

export const connect = () => ({
  type: 'CONNECT',
})

export const setContext = context => ({
  type: 'SET_CONTEXT',
  payload: context,
})

export const addMidiDevice = device => ({
  type: 'ADD_MIDI_DEVICE',
  payload: device,
})

export const midiSupport = isSupport => ({
  type: 'MIDI_SUPPORT',
  payload: isSupport,
})

export const clearCurrentNotes = () => ({
  type: 'CLEAR_CURRENT_NOTES',
})

export const setMidiLogs = isOn => ({
  type: 'SET_MIDI_LOGS',
  payload: isOn,
})

export const addMidiLog = log => ({
  type: 'ADD_MIDI_LOG',
  payload: log,
})

export const clearMidiLogs = () => ({
  type: 'CLEAR_MIDI_LOGS',
})

export const openMidiGate = () => ({ type: 'OPEN_MIDI_GATE' })

export const closeMidiGate = () => ({ type: 'CLOSE_MIDI_GATE' })

export default MIDI
