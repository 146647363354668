import axios from 'axios'
const api = axios.create({ withCredentials: true })
const url = (courseID, assignmentID) =>
  `${process.env.REACT_APP_API}/course/${courseID}/assignment/${assignmentID}`

const ASSIGNMENT = (
  state = {
    _id: '',
    name: '',
    status: 'DRAFT',
    exercises: [],
    settings: {
      figuredBass: false,
      presets: [0, 1, 2],
      positions: [0, 1, 2],
      keys: ['c'],
      totalNumOfQuestions: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_ASSIGNMENT':
      return action.payload

    case 'TOGGLE_EXERCISE':
      return { ...state, ...action.payload }

    case 'UPDATE_ASSIGNMENT_SETTINGS':
      return { ...state, settings: action.payload }

    case 'UPDATE_ASSIGNMENT':
      return action.payload

    case 'PUBLISH_ASSIGNMENT':
      return { ...state, status: 'PUBLISHED' }

    default:
      return state
  }
}

export const fetchAssignment = async (dispatch, course, assignmentID) =>
  dispatch({
    type: 'FETCH_ASSIGNMENT',
    payload: course.assignments.find(x => x._id === assignmentID),
  })

export const setAssignmentExercise = async (
  dispatch,
  course,
  assignment,
  exercise
) => {
  const exercises = assignment.exercises.some(x => x._id === exercise._id)
    ? assignment.exercises.filter(x => x._id !== exercise._id)
    : [...assignment.exercises, exercise]

  const { settings } = assignment
  const { presets, keys, positions } = settings
  const totalNumOfQuestions = [presets, keys, positions].reduce(
    (y, x) => y * x.length,
    exercises.length
  )

  dispatch({
    type: 'TOGGLE_EXERCISE',
    payload: {
      exercises,
      settings: { ...assignment.settings, totalNumOfQuestions },
    },
  })
}

export const updateAssignmentExercises = async (
  dispatch,
  course,
  assignment,
  exercises
) => {
  try {
    await api.put(`${url(course._id, assignment._id)}/exercises`, {
      exercises,
    })
  } catch (err) {
    fetchAssignment(dispatch, course, assignment._id)
  }
}

export const setAssignmentSettings = async (
  dispatch,
  course,
  assignment,
  update
) => {
  const settings = { ...assignment.settings, ...update }

  const { presets, keys, positions } = settings
  const totalNumOfQuestions = [presets, keys, positions].reduce(
    (y, x) => y * x.length,
    assignment.exercises.length
  )

  dispatch({
    type: 'UPDATE_ASSIGNMENT_SETTINGS',
    payload: { ...settings, totalNumOfQuestions },
  })
}

export const updateAssignmentSettings = async (
  dispatch,
  course,
  assignment,
  update
) => {
  try {
    const settings = { ...assignment.settings, ...update }
    await api.put(url(course._id, assignment._id), { settings })
  } catch (err) {
    fetchAssignment(dispatch, course, assignment._id)
  }
}
export const setAssignment = async (dispatch, assignment, update) => {
  dispatch({
    type: 'UPDATE_ASSIGNMENT',
    payload: { ...assignment, ...update },
  })
}
export const updateAssignment = async (
  dispatch,
  course,
  assignment,
  update
) => {
  try {
    await api.put(url(course._id, assignment._id), update)
  } catch (err) {
    fetchAssignment(dispatch, course, assignment._id)
    throw err
  }
}

export const createAssignment = async (courseID, body) =>
  api.post(url(courseID, ''), body)

export const publishAssignment = async (courseID, assignmentID) =>
  api.get(`${url(courseID, assignmentID)}/publish`)

export const removeAssignment = async (courseID, assignmentID) =>
  api.delete(`${url(courseID, assignmentID)}`)

export default ASSIGNMENT
