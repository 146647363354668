import axios from 'axios'
const api = axios.create({ withCredentials: true })
const url = process.env.REACT_APP_API

const LATEST_ASSIGNMENT = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LATEST_ASSIGNMENT':
      return action.payload
    default:
      return state
  }
}

export const fetchLatestAssignment = async (dispatch, user) => {
  if (!user.courses.length)
    return dispatch({
      type: 'SET_LATEST_ASSIGNMENT',
      payload: {},
    })

  const res = await api.get(`${url}/user/latest-assignment`)

  return dispatch({
    type: 'SET_LATEST_ASSIGNMENT',
    payload: res.data,
  })
}

export default LATEST_ASSIGNMENT
