/* eslint-disable no-bitwise */
import { CIRCLE_OF_FIFTHS } from './constants'

// Number => Number
export const degree = n => n % 12

// Number -> Number => Number
export const interval = (a, b) => {
  const y = b - a
  if (y < 0) return (y % 12) + 12
  return y % 12
}

// Number => Number
export const transposeAmount = k => (k % 2 === 0 ? k : k + 6)

// Number -> Number => Number
export const transpose = (n, k) => n + transposeAmount(k)

// ([Number]) -> Number -> Number => Bool
export const canBeInterval = ns => (a, b) => ns.some(n => interval(a, b) === n)

// Number -> Number => Number
export const noteInKey = (n, k) => n - transposeAmount(k)

// Number -> Number => String
export const checkDirection = (c, p) => {
  const y = c - p
  if (y === 0) return 'oblique'
  return y < 0 ? 'down' : 'up'
}

// Number -> Number -> Number => Number
export const uniqueCaseID = (x, y, z) => (y << x) | (z << y)

// Number -> Number => Bool
export const isLargerThanFifth = (a, b) => Math.abs(a - b) > 7

// Number -> Number => Number || False
export const isFifthOrOctave = (dx, d) => {
  const y = interval(dx, d)
  return y === 7 || y === 0 ? y : false
}

// Number -> Number => Object
export const direction = (dx, d) => {
  const y = dx - d
  if (y === 0) return { direction: 'stationary', distance: y }
  if (y > 0) return { direction: 'up', distance: y }
  return { direction: 'down', distance: y }
}

// Char => Number
export const keyCharToInt = key => CIRCLE_OF_FIFTHS.indexOf(key) - 6
