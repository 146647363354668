import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { BsPerson } from 'react-icons/bs'

import Logo from '../Shared/Global/Logo'
import Signin from './SignIn'
import Signup from './SignUp'
import ResetPassword from './ResetPassword'

const useQuery = () => new URLSearchParams(useLocation().search)

export default function Guest() {
  const history = useHistory()
  const email = useQuery().get('email')
  const isSignin = useQuery().get('signin')
  const isResetPassword = useQuery().get('reset-password')
  const token = useQuery().get('auth')

  if (isResetPassword && token) return <ResetPassword token={token} />

  return (
    <Container fluid>
      <Row className="align-items-center justify-content-center vh-100">
        <div className="bg-white shadow-sm rounded col col-md-5 align-items-center p-3 d-flex flex-column">
          <Logo
            style={{ fill: '#01b6d5', maxWidth: '200px' }}
            onClick={() =>
              window.location.replace(
                'https://www.' + window.location.host.slice(4)
              )
            }
          />

          <h4 className="text-dark mt-4">
            <BsPerson className="icon" />
            {!isSignin ? 'Create an account' : 'Sign In'}
          </h4>

          {!isSignin ? <Signup emailQuery={email} /> : <Signin />}

          {!isSignin ? (
            <small className="text-muted">
              already a user?{' '}
              <span
                className="text-primary"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/?signin=true')}
              >
                signin
              </span>
            </small>
          ) : (
            <small className="text-muted">
              not a user?{' '}
              <span
                className="text-primary"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/')}
              >
                create an account
              </span>
            </small>
          )}
        </div>
      </Row>
    </Container>
  )
}
