const init = {
  show: false,
  type: 'neutral',
  title: '',
  body: '',
}

const ALERT = (state = { ...init }, action) => {
  switch (action.type) {
    case 'SET_ALERT':
      return action.payload

    case 'SET_STICKY_ALERT':
      return { ...state, ...action.payload }

    case 'RESET_ALERT':
      return { ...init }

    default:
      return state
  }
}

export const setAlert = (value, lifespan, dispatch) => {
  const type = 'SET_ALERT'
  const open = () => ({
    type,
    payload: { ...value, show: true },
  })
  const close = () => ({
    type,
    payload: { ...value, show: false },
  })

  dispatch(open(value))
  return setTimeout(() => {
    dispatch(close(value))
  }, lifespan)
}

export const setStickyAlert = (value, dispatch) => {
  const action = () => ({
    type: 'SET_STICKY_ALERT',
    payload: { ...value, show: true },
  })
  return dispatch(action(value))
}

export const resetAlert = () => ({ type: 'RESET_ALERT' })

export default ALERT
