import findChord from './findChord'

const [BASS, TENOR, SOPRANO] = [0, 1, 2]

const doubleVoice = (voice, step) => [
  ...step.slice(0, voice),
  step[voice],
  ...step.slice(voice),
]

export const buildDoubledVariations = step => [
  doubleVoice(BASS, step),
  doubleVoice(TENOR, step),
  doubleVoice(SOPRANO, step),
]

// (func) -> Number -> Chord -> Chord -> Any => [Claim]
const checkDoubledVariations =
  func =>
  (index, a, b, ...rest) => {
    const alen = a.raw.length // (current/player)
    const blen = b ? b.raw.length : 4 // (previous/computer)

    const { mode, key, figuredBass } = rest[0]

    try {
      if (alen === 3 && blen === alen) {
        const aVars = buildDoubledVariations(a.raw)
        const bVars = buildDoubledVariations(b.raw)

        return aVars.map(av =>
          bVars
            .map(bv => {
              const claims = func(
                index,
                findChord(av, key, mode, figuredBass),
                findChord(bv, key, mode, figuredBass),
                ...rest
              )
              if (!claims.filter(x => x).length) throw new Error()
              return claims
            })
            .pop()
        )
      }

      if (alen === 3) {
        const aVars = buildDoubledVariations(a.raw)
        return aVars
          .map(av => {
            const claims = func(
              index,
              findChord(av, key, mode, figuredBass),
              b,
              ...rest
            )
            if (!claims.filter(x => x).length) throw new Error()
            return claims
          })
          .pop()
      }

      if (blen === 3) {
        const bVars = buildDoubledVariations(b.raw)

        return bVars
          .map(bv => {
            const claims = func(
              index,
              a,
              findChord(bv, key, mode, figuredBass),
              ...rest
            )
            if (!claims.filter(x => x).length) throw new Error()
            return claims
          })
          .pop()
      }

      return func(index, a, b, ...rest)
    } catch (err) {
      return []
    }
  }

export default checkDoubledVariations
