import React from 'react'

export default function Radio({
  disabled,
  checked,
  onChange,
  label,
  name,
  value,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className="checkbox"
      style={{ cursor: disabled ? 'default' : 'pointer' }}
    >
      {label}
      <input
        type="radio"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
        value={value}
      />
      <span className="radio-checkmark" />
    </label>
  )
}
