import React from 'react'
import { Container, Row, Spinner } from 'react-bootstrap'

const Spin = ({ inline }) => {
  if (inline)
    return (
      <Spinner
        className="ms-2"
        animation="border"
        variant="primary"
        size="sm"
      />
    )
  return (
    <div className="col d-flex flex-column align-items-center justify-content-center">
      <Spinner animation="border" variant="primary" />
      <span className="mt-2">loading...</span>
    </div>
  )
}

export default function Loader({ local, inline }) {
  if (local) return <Spin inline={local && inline} />

  return (
    <Container fluid>
      <Row className="vh-100">
        <Spin />
      </Row>
    </Container>
  )
}
