export const FUNCTIONAL_HARMONY = [
  { root: 0, formula: [4, 3], minor: 'III', major: 'I' },
  { root: 0, formula: [3, 4], minor: 'iii', major: 'i' },
  { root: 2, formula: [3, 4], minor: 'iv', major: 'ii' },
  { root: 2, formula: [4, 3], minor: 'IV', major: 'II' },
  { root: 4, formula: [3, 4], minor: 'v(m)', major: 'iii' },
  { root: 4, formula: [4, 3], minor: 'V', major: 'V/vii' },
  { root: 5, formula: [4, 3], minor: 'VI', major: 'IV' },
  { root: 5, formula: [3, 4], minor: 'vi', major: 'iv' },
  { root: 7, formula: [4, 3], minor: 'V/III', major: 'V' },
  { root: 7, formula: [3, 4], minor: 'vii', major: 'v(m)' },
  { root: 8, formula: [3, 3], minor: 'viio', major: 'viio/vi' },
  { root: 9, formula: [3, 4], minor: 'i', major: 'vi' },
  { root: 9, formula: [4, 3], minor: 'I', major: 'VI' },
  { root: 11, formula: [3, 3], minor: 'iio', major: 'viio' },
]

export const INVERSIONS = [
  { distance: [0], triad: '', seventh: '7' },
  { distance: [3, 4], triad: '6', seventh: '65' },
  { distance: [7], triad: '64', seventh: '43' },
  { distance: [10], triad: '', seventh: '42' },
]

export const FIGURED_BASS_SPECIAL = [
  ['V', '43'],
  ['V', '42'],
  ['viio', '46'],
  ['viio', '6'],
]

export const VOICES = ['Bass', 'Tenor', 'Alto', 'Soprano']

export const COMBINATIONS = [
  [0, 0, 0],
  [0, 0, 1],
  [0, 0, 2],
  [0, 1, 0],
  [0, 1, 1],
  [0, 1, 2],
  [0, 2, 0],
  [0, 2, 1],
  [0, 2, 2],
  [1, 0, 0],
  [1, 0, 1],
  [1, 0, 2],
  [1, 1, 0],
  [1, 1, 1],
  [1, 1, 2],
  [1, 2, 0],
  [1, 2, 1],
  [1, 2, 2],
  [2, 0, 0],
  [2, 0, 1],
  [2, 0, 2],
  [2, 1, 0],
  [2, 1, 1],
  [2, 1, 2],
  [2, 2, 0],
  [2, 2, 1],
  [2, 2, 2],
]

export const CIRCLE_OF_FIFTHS = [
  'gb',
  'db',
  'ab',
  'eb',
  'bb',
  'f',
  'c',
  'g',
  'd',
  'a',
  'e',
  'b',
]
