import React from 'react'
import { BsDot } from 'react-icons/bs'

export default function Checkbox({
  disabled,
  checked,
  onChange,
  label,
  hideCheckMark,
}) {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={`checkbox ${disabled && 'disabled'} ${
        hideCheckMark && 'ml-n4'
      }`}
    >
      {hideCheckMark ? <BsDot /> : null}
      {label}
      {!hideCheckMark ? (
        <>
          <input
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
          <span className="checkmark" />
        </>
      ) : null}
    </label>
  )
}
