import axios from 'axios'
const api = axios.create({ withCredentials: true })
const url = process.env.REACT_APP_API + '/course'

const COURSE = (
  state = {
    approved: [],
    pending: [],
    assignments: [],
    due: [],
    completed: [],
    teacher: {},
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_COURSE':
      return action.payload

    default:
      return state
  }
}

export const fetchCourse = async (dispatch, courseID) => {
  const { data } = await api.get(`${url}/${courseID}`)

  dispatch({
    type: 'FETCH_COURSE',
    payload: data,
  })

  return data
}

export const createCourse = async body => api.post(url, body)

export const updateCourseName = async (name, courseID) =>
  api.put(`${url}/${courseID}`, { name })

export const removeCourse = async courseID =>
  api.put(`${url}/${courseID}`, { status: 'ARCHIVED' })

export const dropStudent = async (dispatch, studentEmail, courseID) => {
  try {
    await api.post(`${url}/${courseID}/drop`, { studentEmail })
    return fetchCourse(dispatch, courseID)
  } catch (err) {
    return err
  }
}

export default COURSE
