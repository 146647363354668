import { combineReducers } from 'redux'
import USER from './user.state'
import COURSE from './course.state'
import ASSIGNMENT from './assignment.state'
import LIBRARY from './library.state'
import MODALS from './modals.state'
import MIDI from './midi.state'
import ALERT from './alert.state'
import CONTENT from './content.state'
import INSTRUCTOR from './instructor.state'
import LATEST from './latest.state'
import SCORE from './score.state'
import SUBSCRIPTION from './subscription.state'

const reducer = combineReducers({
  USER,
  COURSE,
  ASSIGNMENT,
  LIBRARY,
  MODALS,
  MIDI,
  ALERT,
  CONTENT,
  INSTRUCTOR,
  LATEST,
  SCORE,
  SUBSCRIPTION,
})

export default reducer
