import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import { fetchUser } from './redux/user.state'
import Guest from './components/Guest'
import Teacher from './components/Teacher'
import Student from './components/Student'
import Loader from './components/Shared/Global/Loader'

export default function App() {
  const dispatch = useDispatch()
  const user = useSelector(s => s.USER)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUser(dispatch).then(() => setLoading(false))
  }, [dispatch])

  if (loading) return <Loader />

  if (!Object.keys(user).length || !user._id)
    return (
      <Router>
        <Guest />
      </Router>
    )

  if (user.role === 'teacher') return <Teacher />

  return <Student />
}
