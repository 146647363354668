import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Loader from '../Shared/Global/Loader'

const ProfileSettings = lazy(() => import('../Shared/ProfileSettings'))
const Dashboard = lazy(() => import('../Shared/Global/Dashboard'))
const HelpCenter = lazy(() => import('../Shared/HelpCenter'))
const TeacherModals = lazy(() => import('./modals'))
const TeacherAssignment = lazy(() => import('./TeacherAssignment'))
const TeacherCourse = lazy(() => import('./TeacherCourse'))
const Studio = lazy(() => import('../Studio'))

export default function Teacher() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <TeacherModals />
        <Switch>
          <Route exact path="/course/:courseID" component={TeacherCourse} />
          <Route exact path="/help" component={HelpCenter} />
          <Route
            exact
            path="/course/:courseID/assignment/:assignmentID/studio"
            component={Studio}
          />
          <Route
            exact
            path="/course/:courseID/assignment/:assignmentID/:step?"
            component={TeacherAssignment}
          />
          <Route path="/profile-settings" component={ProfileSettings} />
          <Route path="/" component={Dashboard} />
        </Switch>
      </Router>
    </Suspense>
  )
}
