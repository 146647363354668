import React from 'react'

export default function Logo({ width = '50%', className, style, onClick }) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 150 19.3"
        width={width}
        className={className}
        style={style}
        onClick={onClick}
      >
        <path
          d="M.77,19a.57.57,0,0,1-.43-.18.56.56,0,0,1-.17-.43V1.11A.6.6,0,0,1,.34.65.6.6,0,0,1,.77.48H2.1a.65.65,0,0,1,.48.16A1.32,1.32,0,0,1,2.79.9L8.38,11.61,14,.9a1.21,1.21,0,0,1,.2-.26.59.59,0,0,1,.46-.16H16a.66.66,0,0,1,.47.17.64.64,0,0,1,.17.46V18.43a.6.6,0,0,1-.17.43A.63.63,0,0,1,16,19h-1.3a.61.61,0,0,1-.61-.61v-13L9.66,14a.94.94,0,0,1-.32.41,1,1,0,0,1-.53.14H8a.88.88,0,0,1-.53-.14A1.22,1.22,0,0,1,7.14,14L2.68,5.38V18.43a.56.56,0,0,1-.17.43.57.57,0,0,1-.44.18Z"
          transform="translate(-0.17)"
        />
        <path
          d="M26,19.3a5.22,5.22,0,0,1-2.87-.75,4.75,4.75,0,0,1-1.79-2.11,7.43,7.43,0,0,1-.61-3.1V5.86a.58.58,0,0,1,.61-.61h1.32a.6.6,0,0,1,.44.17.58.58,0,0,1,.17.44V13.2c0,2.64,1.15,3.95,3.45,3.95a3.5,3.5,0,0,0,2.64-1,4.07,4.07,0,0,0,1-2.91V5.86A.58.58,0,0,1,31,5.25H32.3a.56.56,0,0,1,.42.17.61.61,0,0,1,.16.44V18.43a.59.59,0,0,1-.16.43.53.53,0,0,1-.42.18H31.05a.59.59,0,0,1-.44-.18.6.6,0,0,1-.17-.43V17.26a5.09,5.09,0,0,1-4.43,2Z"
          transform="translate(-0.17)"
        />
        <path
          d="M41.6,19.3a7.61,7.61,0,0,1-2.54-.37,6.08,6.08,0,0,1-1.73-.9,4.37,4.37,0,0,1-1-1.06,2.16,2.16,0,0,1-.36-.85.47.47,0,0,1,.19-.45.67.67,0,0,1,.42-.16h1.19a.53.53,0,0,1,.23,0,.87.87,0,0,1,.25.22c.23.25.49.5.77.75a3.34,3.34,0,0,0,1,.61,4.31,4.31,0,0,0,1.55.24,4.41,4.41,0,0,0,2.23-.52,1.67,1.67,0,0,0,.87-1.53,1.53,1.53,0,0,0-.36-1.06,3.12,3.12,0,0,0-1.27-.71,22.29,22.29,0,0,0-2.51-.67A9,9,0,0,1,38.08,12a3.4,3.4,0,0,1-1.33-1.3A3.66,3.66,0,0,1,36.35,9a3.47,3.47,0,0,1,.59-1.89,4.35,4.35,0,0,1,1.71-1.5A6.07,6.07,0,0,1,41.47,5a7.12,7.12,0,0,1,2.36.35,5.62,5.62,0,0,1,1.62.86,4.62,4.62,0,0,1,.95,1,2.09,2.09,0,0,1,.35.85.54.54,0,0,1-.16.44.65.65,0,0,1-.43.17H45.05a.59.59,0,0,1-.31-.08,1.24,1.24,0,0,1-.22-.19,7.64,7.64,0,0,0-.62-.68A2.82,2.82,0,0,0,43,7.15a4.15,4.15,0,0,0-1.5-.23,3,3,0,0,0-2,.56,1.76,1.76,0,0,0-.66,1.4,1.49,1.49,0,0,0,.29.9,2.42,2.42,0,0,0,1.11.72,20.35,20.35,0,0,0,2.44.69,9,9,0,0,1,2.76.93,3.68,3.68,0,0,1,1.43,1.35,3.63,3.63,0,0,1,.43,1.78,3.57,3.57,0,0,1-.64,2,4.21,4.21,0,0,1-1.91,1.47A8,8,0,0,1,41.6,19.3Z"
          transform="translate(-0.17)"
        />
        <path
          d="M50.75,2.68a.62.62,0,0,1-.44-.17.64.64,0,0,1-.17-.44V.64a.64.64,0,0,1,.17-.45A.56.56,0,0,1,50.75,0h1.67a.58.58,0,0,1,.45.19.61.61,0,0,1,.18.45V2.07a.6.6,0,0,1-.18.44.64.64,0,0,1-.45.17ZM51,19a.59.59,0,0,1-.44-.18.6.6,0,0,1-.17-.43V5.86A.58.58,0,0,1,51,5.25h1.27a.6.6,0,0,1,.44.17.58.58,0,0,1,.17.44V18.43a.56.56,0,0,1-.17.43.57.57,0,0,1-.44.18Z"
          transform="translate(-0.17)"
        />
        <path
          d="M56.36,8.78a6.88,6.88,0,0,1,2.26-2.47,5.57,5.57,0,0,1,3.06-1h.62a.8.8,0,0,0,.22.05,2,2,0,0,1,.4.09l.65.24L64,5.9a8.67,8.67,0,0,1,1,.6,3.87,3.87,0,0,1,.86.81,3,3,0,0,1,.79,2.06A2.58,2.58,0,0,1,66,11.12a2.53,2.53,0,0,1-1.71.69,2,2,0,0,1-2.06-1.89,1.91,1.91,0,0,1,.55-1.41,2.19,2.19,0,0,1,1.3-.82l.28,0,.27-.1.1-.21a1.28,1.28,0,0,0-1-.82,3.11,3.11,0,0,0-1.48-.35,2.54,2.54,0,0,0-1.51.49,2.44,2.44,0,0,0-.68.51,4,4,0,0,0-.52.62,9.33,9.33,0,0,0-.69,4.15,15.13,15.13,0,0,0,.24,3.71,2.91,2.91,0,0,0,3,2.55,3.51,3.51,0,0,0,3-2.07A8.14,8.14,0,0,0,66,13.7h.8a7.13,7.13,0,0,1-.52,2.16,6.63,6.63,0,0,1-1.1,1.75,5.16,5.16,0,0,1-3.85,1.55,4.7,4.7,0,0,1-3.12-1.1,6.16,6.16,0,0,1-2.06-2.75,11.58,11.58,0,0,1-.52-1.72,7.81,7.81,0,0,1-.21-1.71A6,6,0,0,1,56.36,8.78Z"
          transform="translate(0)"
        />
        <path
          d="M70.42,2.68A.62.62,0,0,1,70,2.51a.64.64,0,0,1-.17-.44V.64A.64.64,0,0,1,70,.19.56.56,0,0,1,70.42,0h1.67a.58.58,0,0,1,.45.19.62.62,0,0,1,.19.45V2.07a.61.61,0,0,1-.19.44.64.64,0,0,1-.45.17ZM70.63,19a.6.6,0,0,1-.44-.18.6.6,0,0,1-.17-.43V5.86a.62.62,0,0,1,.17-.44.64.64,0,0,1,.44-.17h1.28a.6.6,0,0,1,.43.17.58.58,0,0,1,.17.44V18.43a.56.56,0,0,1-.17.43.57.57,0,0,1-.43.18Z"
          transform="translate(-0.17)"
        />
        <path
          d="M80.28,19.3a5.46,5.46,0,0,1-2.41-.53,4.66,4.66,0,0,1-1.75-1.43,3.36,3.36,0,0,1-.66-2,3.44,3.44,0,0,1,1.48-2.92A8.58,8.58,0,0,1,80.81,11l4-.56V9.62a2.63,2.63,0,0,0-.73-2,3.26,3.26,0,0,0-2.37-.72,3.47,3.47,0,0,0-1.91.48,2.52,2.52,0,0,0-1,1.22.57.57,0,0,1-.56.39H77a.53.53,0,0,1-.44-.17.59.59,0,0,1-.14-.41,2.27,2.27,0,0,1,.3-1,4,4,0,0,1,.94-1.14,5.64,5.64,0,0,1,1.63-.94A6.65,6.65,0,0,1,81.69,5a7.5,7.5,0,0,1,2.68.42A4.07,4.07,0,0,1,87,8.06a6,6,0,0,1,.28,1.78v8.59a.61.61,0,0,1-.61.61H85.43a.55.55,0,0,1-.45-.18.59.59,0,0,1-.16-.43V17.29A4.89,4.89,0,0,1,82.43,19,6.67,6.67,0,0,1,80.28,19.3Zm.56-2a4.29,4.29,0,0,0,2-.46,3.32,3.32,0,0,0,1.42-1.45,5.28,5.28,0,0,0,.51-2.46V12.2l-3.07.45a7.1,7.1,0,0,0-2.84.88,1.84,1.84,0,0,0-.95,1.58,1.8,1.8,0,0,0,.43,1.24,2.68,2.68,0,0,0,1.12.72A4.11,4.11,0,0,0,80.84,17.31Z"
          transform="translate(-0.17)"
        />
        <path
          d="M91.68,19a.6.6,0,0,1-.44-.18.6.6,0,0,1-.17-.43V5.86a.62.62,0,0,1,.17-.44.64.64,0,0,1,.44-.17h1.25a.6.6,0,0,1,.43.17.59.59,0,0,1,.18.44V7A5.09,5.09,0,0,1,98,5a5.43,5.43,0,0,1,2.93.76,4.69,4.69,0,0,1,1.83,2.1,7.23,7.23,0,0,1,.63,3.11v7.48a.6.6,0,0,1-.17.43.6.6,0,0,1-.44.18h-1.33a.59.59,0,0,1-.44-.18.6.6,0,0,1-.17-.43V11.08a4.32,4.32,0,0,0-.9-2.9,3.29,3.29,0,0,0-2.65-1,3.46,3.46,0,0,0-2.64,1,4.06,4.06,0,0,0-1,2.9v7.35A.61.61,0,0,1,93,19Z"
          transform="translate(-0.17)"
        />
        <path
          d="M107.7,19a.63.63,0,0,1-.48-.2.66.66,0,0,1-.19-.47V1.14a.63.63,0,0,1,.67-.66h7.42a10,10,0,0,1,3.83.67,5.53,5.53,0,0,1,2.54,2,6,6,0,0,1,.92,3.42,5.8,5.8,0,0,1-.92,3.37,5.34,5.34,0,0,1-2.54,2,10.38,10.38,0,0,1-3.83.64H112v5.8a.63.63,0,0,1-.2.47.66.66,0,0,1-.49.2Zm4.18-10.13H115a2.68,2.68,0,0,0,1.81-.59,2.2,2.2,0,0,0,.68-1.77,2.55,2.55,0,0,0-.59-1.7,2.34,2.34,0,0,0-1.9-.69h-3.11Z"
          transform="translate(-0.17)"
        />
        <path
          d="M125.32,19a.62.62,0,0,1-.47-.2.66.66,0,0,1-.19-.47V5.91a.63.63,0,0,1,.19-.46.62.62,0,0,1,.47-.2h3.05a.66.66,0,0,1,.49.2.61.61,0,0,1,.2.46V7a4.74,4.74,0,0,1,1.69-1.27A5.37,5.37,0,0,1,133,5.25h1.16a.62.62,0,0,1,.48.19.65.65,0,0,1,.19.47V8.64a.7.7,0,0,1-.19.47.63.63,0,0,1-.48.2h-2.57a2.27,2.27,0,0,0-1.68.59,2.28,2.28,0,0,0-.6,1.69v6.78a.68.68,0,0,1-.69.67Z"
          transform="translate(-0.17)"
        />
        <path
          d="M143.11,19.3a8.77,8.77,0,0,1-3.75-.72,5.25,5.25,0,0,1-2.33-2,6.82,6.82,0,0,1-.91-3.19c0-.36,0-.76,0-1.21s0-.84,0-1.18a6.49,6.49,0,0,1,.95-3.21,5.63,5.63,0,0,1,2.37-2,9.79,9.79,0,0,1,7.37,0,5.6,5.6,0,0,1,2.38,2,6.59,6.59,0,0,1,.95,3.21c0,.34,0,.73,0,1.18s0,.85,0,1.21a6.82,6.82,0,0,1-.92,3.19,5.19,5.19,0,0,1-2.33,2A8.82,8.82,0,0,1,143.11,19.3Zm0-3.23a2,2,0,0,0,1.74-.72,3.9,3.9,0,0,0,.62-2.15c0-.26,0-.62,0-1.06s0-.79,0-1.06A3.9,3.9,0,0,0,144.85,9a2.4,2.4,0,0,0-3.45,0,3.67,3.67,0,0,0-.62,2.13c0,.27,0,.62,0,1.06s0,.8,0,1.06a3.66,3.66,0,0,0,.62,2.15A2,2,0,0,0,143.11,16.07Z"
          transform="translate(-0.17)"
        />
      </svg>
    </>
  )
}
