import { degree, interval, noteInKey } from './base-functions'
import {
  FUNCTIONAL_HARMONY,
  INVERSIONS,
  FIGURED_BASS_SPECIAL,
} from './constants'
import { findFormula, findRoot, sortChord } from './step-functions'
import { isSeventh } from './dominants'

// [Number] -> Number -> Sting => String
export const findHarmony = (formula, root, mode = 'major') => {
  if (!formula.length) return ''
  const size = formula.length
  const h = FUNCTIONAL_HARMONY.find(
    x =>
      x.formula.slice(0, size).join('') === formula.join('') && root === x.root
  )
  if (h) return h[mode]
  return findHarmony(formula.slice(0, size - 1), root, mode)
}

// Number -> [Number] -> [Number] => String
export const findInversion = (root, transposed, sorted) => {
  const i = INVERSIONS.find(c =>
    c.distance.some(x => x === interval(root, transposed[0]))
  )
  if (!i) return ''
  return isSeventh(sorted) ? i.seventh : i.triad
}

// Chord.Inversion => Bool
export const isRootPosition = inversion => ['', '7'].some(x => x === inversion)

// String -> String => String
export const insertInversion = (
  symbol,
  inversion,
  useFiguredBass,
  mode,
  key = 0
) => {
  const accidental = key < -2 ? 'n' : '#'
  if (useFiguredBass) {
    if (mode === 'minor') {
      if (symbol === 'V' && inversion === '') return `${accidental}3`
      if (symbol === 'V' && inversion === '43') return `${accidental}643`
      if (FIGURED_BASS_SPECIAL.some(c => c[0] === symbol && c[1] === inversion))
        return `${accidental}${inversion}`
    }
    return inversion
  }
  const x = symbol.split('/')
  if (x.length < 2) x.push(inversion)
  else x.splice(1, 0, `${inversion}/`)
  return x.join('')
}
// [Number] -> Number -> String -> Bool => Chord
const findChord = (step, key = 0, mode = 'major', useFiguredBass) => {
  const transposed = step.map(n => noteInKey(n, key))
  const root =
    findFormula([...new Set(step)]).join('') === '044'
      ? step[0] % 12
      : findRoot([...new Set(transposed.map(degree))])
  const sorted = sortChord(root, transposed)
  const formula = findFormula(sorted)
  const symbol = findHarmony(formula, root, mode)
  const inversion = findInversion(root, transposed.map(degree), sorted)
  return {
    transposed,
    sorted,
    root,
    formula,
    raw: step,
    key,
    symbol,
    mode,
    inversion,
    figure: insertInversion(symbol, inversion, useFiguredBass, mode, key),
  }
}

export default findChord

// Number -> Number => Bool
const isUnison = (a, b) => interval(a, b) === 0
const is3rd = (a, b) => [3, 4].some(x => interval(a, b) === x)
const is5th = (a, b) => [7].some(x => interval(a, b) === x)
const is7th = (a, b) => [10].some(x => interval(a, b) === x)

// Chord => [String]
export const findStructure = c =>
  c.transposed.map(degree).reduce((y, n) => {
    if (isUnison(c.root, n)) return [...y, 'root']
    if (is3rd(c.root, n)) return [...y, '3rd']
    if (is5th(c.root, n)) return [...y, '5th']
    if (is7th(c.root, n)) return [...y, '7th']
    return [...y, null]
  }, [])

// String -> String => String
export const cad64 = (current, next) =>
  current.toLowerCase() === 'i64' && next.toLowerCase()[0] === 'v'
    ? 'Cad64'
    : current
