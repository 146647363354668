import { degree, interval } from './base-functions'
import claim from './claims'
import { findStructure } from './findChord'
import { isFormula } from './step-functions'
import doubles from './doubleVoice'

const isMajTriad = formula =>
  isFormula('43')(formula) || isFormula('4')(formula)
const isMinTriad = formula =>
  isFormula('34')(formula) || isFormula('3')(formula)

// [Number] => Bool
export const isSeventh = sorted =>
  [10].some(x => x === interval(sorted[0], sorted[sorted.length - 1]))

// Chord => Bool
export const canBeDominant = chord =>
  isFormula('433')(chord.formula) || isFormula('46')(chord.formula)

// Chord => Bool
export const isDominantWith7th = chord =>
  canBeDominant(chord) && isSeventh(chord.sorted)

// Chord -> Chord => Bool
export const isDominantToTonic = (curr, prev) =>
  interval(prev.root, curr.root) === 5 &&
  isDominantWith7th(prev) &&
  (isMajTriad(curr.formula) || isMinTriad(curr.formula))

// Number -> Chord => Claim || Null
export const checkDominantDoubling = (index, dominant) => {
  const structure = findStructure(dominant)
  const thirds = structure.filter(x => x === '3rd')
  const sevenths = structure.filter(x => x === '7th')
  if (thirds.length > 1)
    return claim(
      'bad-doubling-dominant',
      index,
      [structure.slice(1).indexOf(thirds[thirds.length - 1]) + 1],
      'leading-Tone'
    )
  if (sevenths.length > 1)
    return claim(
      'bad-doubling-dominant',
      index,
      [structure.slice(1).indexOf(thirds[thirds.length - 1]) + 1],
      '7th'
    )
  return null
}

const isSecond = (a, b) => [1, 2].some(x => x === interval(a, b))

// Number -> Chord -> Chord => Claim || Null
export const checkEnterToDisonance = (index, dominant, prev) => {
  const structure = findStructure(dominant)
  const voice = structure.indexOf(structure.find(x => x === '7th'))
  const seventh = dominant.transposed[voice]

  if (dominant.inversion === '42') return null

  if (
    ['i', 'I'].some(x => x === prev.symbol) &&
    prev.inversion === '' &&
    ['43', '65'].some(x => dominant.inversion === x)
  )
    return null

  const isSevenInPrev = prev.transposed.some(n => degree(n) === degree(seventh))
  if (isSevenInPrev && prev.transposed[voice] !== seventh)
    return claim('disonance-enter-dominant', index, [voice], isSevenInPrev)

  const canDescend = prev.transposed
    .map(degree)
    .some(n => isSecond(degree(seventh), n))
  if (
    canDescend &&
    ![seventh + 1, seventh + 2].some(x => x === prev.transposed[voice])
  )
    return claim('disonance-enter-dominant', index, [voice])

  return null
}

// Number -> Chord -> Chord => [Claim] || []
export const checkDominantTonicResolution = (index, tonic, dominant) => {
  const tonStruct = findStructure(tonic)
  const domStruct = findStructure(dominant)

  const clms = domStruct
    .map((x, i) => {
      const y = tonStruct[i]

      if (x === 'root') {
        if (
          i === tonStruct.length - 1 &&
          dominant.inversion === '42' &&
          tonic.inversion === '6' &&
          !['5th', 'root'].some(n => n === y)
        ) {
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )
        }

        if (
          i > 0 &&
          y !== '5th' &&
          dominant.inversion !== '42' &&
          tonic.inversion !== '6'
        )
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )
      }

      if (x === '3rd') {
        if (
          i > 0 &&
          i < domStruct.length - 1 &&
          dominant.inversion === '7' &&
          !['root', '5th'].some(n => n === y)
        )
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )

        if (y !== 'root' && i === domStruct.length - 1)
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )
      }

      if (x === '5th') {
        if (
          dominant.inversion === '42' &&
          tonic.inversion === '6' &&
          ['root', '5th'].some(n => n === domStruct[domStruct.length - 1]) &&
          !['root', '5th'].some(n => n === y)
        )
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )

        if (
          dominant.inversion !== '43' &&
          tonic.inversion !== '6' &&
          y !== 'root'
        )
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )
      }

      if (x === '7th') {
        if (
          dominant.inversion === '43' &&
          tonic.inversion === '6' &&
          y === '5th'
        )
          return null

        if (y !== '3rd')
          return claim(
            'bad-tonic-resolution',
            index,
            new Array(domStruct.length - 1).fill(1).map((n, j) => n + j)
          )
      }

      return null
    })
    .filter(c => c)

  if (clms.length) return [clms[0]]
  return []
}

// Number -> Chord -> Chord => [Claim]
const checkDominantMotion = (index, current, prev) => {
  const claims = []
  const isCurrDominant = canBeDominant(current)

  if (isCurrDominant) claims.push(checkDominantDoubling(index, current))

  if (index > 0) {
    if (isCurrDominant) {
      claims.push(checkEnterToDisonance(index, current, prev))
    }
    const t2d = isDominantToTonic(current, prev)
    if (t2d) {
      claims.push(...checkDominantTonicResolution(index, current, prev))
    }
  }
  return claims.filter(x => x)
}

export default doubles(checkDominantMotion)
