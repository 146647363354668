import { canBeInterval, degree, interval, uniqueCaseID } from './base-functions'

// Number -> Number => Bool
const canFormThird = canBeInterval([3, 4])
const canFormSeventh = canBeInterval([10, 11])
export const canFormFifth = canBeInterval([6, 7])

// Number -> Number => Number || False
const isFifthOrOctave = (a, b) => {
  const y = interval(a, b)
  return [0, 7].some(x => x === y) ? y : false
}

// [Number] => Number || undefined
export const findRoot = (step, prev = []) => {
  if (!step.length) return undefined
  if (step.length === prev.length) return prev[0]
  if (step.length === 1) return step[0] % 12

  const sorted = step.reduce((y, x) => {
    const others = step.filter(n => n !== x)
    const third = others.some(n => canFormThird(x, n))
    if (third) {
      const isSeventhFromN = y.some(n => canFormSeventh(n, x))
      if (isSeventhFromN) return y
      const isNfromseveth = y.find(n => canFormSeventh(x, n))
      if (isNfromseveth) return [...y.filter(n => n !== isNfromseveth), x]
      return [...y, x]
    }
    return y
  }, [])
  return findRoot([...new Set(sorted)], step)
}

// Number -> [Number] => [Number]
export const sortChord = (root, step) => [
  root,
  ...[...new Set(step.map(degree))]
    .filter(n => n !== root)
    .reduce((y, x) => [...y, { n: x, i: interval(root, x) }], [])
    .sort((a, b) => a.i - b.i)
    .map(x => x.n),
]

// [Number] => [Number]
export const findFormula = step =>
  step.slice(1).reduce((y, x) => {
    const i = step.indexOf(x)
    return [...y, interval(step[i - 1], x)]
  }, [])

// String -> ([Number]) => Bool
export const isFormula = str => formula => formula.join('') === str

// [Number] -> [Number] => [Number]
export const compareSteps = (a, b) =>
  b.filter((x, i) => x !== a[i]).map(x => b.indexOf(x))

// [Number] => [ValidCases]
export const fifthsAndOctaves = step =>
  step.slice(0, step.length - 1).reduce((y, voice, i) => {
    step.slice(i + 1).forEach(x => {
      const j = step.indexOf(x)
      const d = isFifthOrOctave(voice, x)
      const uid = uniqueCaseID(i, j, d)
      if (d !== false) {
        y.push({
          voices: [i, j],
          interval: d,
          base: voice,
          uid,
        })
      }
    })
    return y
  }, [])

// [Any] -> [Any] -> Object
export const sortArraysByLength = (a, b) =>
  a.length >= b.length ? { short: b, long: a } : { short: a, long: b }
