import axios from 'axios'
const api = axios.create({ withCredentials: true })
const url = process.env.REACT_APP_API + '/user'

const USER = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_USER':
      return action.payload

    default:
      return state
  }
}

export const fetchUser = async dispatch => {
  const { data } = await api.get(url)
  return dispatch({
    type: 'FETCH_USER',
    payload: data,
  })
}

export const signin = async (dispatch, body) => {
  await api.post(`${url}/signin`, body)
  return fetchUser(dispatch)
}

export const signout = async () => {
  await api.get(`${url}/signout`)
  return window.location.replace('/')
}

export const removeNotification = async (dispatch, notificationID) => {
  await api.delete(`${url}/remove-notification/${notificationID}`)
  return fetchUser(dispatch)
}

export const updateUser = async (dispatch, body) => {
  await api.put(url, body)
  return fetchUser(dispatch)
}

export default USER
