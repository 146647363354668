import axios from 'axios'

const URL = process.env.REACT_APP_API

const api = axios.create({ withCredentials: true })

export const mockRequest = (fullfil = true, time = 2) =>
  new Promise((res, rej) => {
    setTimeout(() => {
      if (fullfil) return res({ data: 'success' })
      return rej(new Error('fail'))
    }, time * 1000)
  })

export const signUp = async body => api.post(`${URL}/user/signup`, body)

export const signIn = async body => api.post(`${URL}/user/signin`, body)

export const signOut = async () => api.get(`${URL}/user/signout`)

export const updateUser = async body => api.put(`${URL}/user`, body)

export const addCourse = async body => api.post(`${URL}/course`, body)

export const addAssignment = async (id, body) =>
  api.post(`${URL}/course/${id}/assignment`, body)

export const deleteNotification = async id =>
  api.delete(`${URL}/user/notification/${id}`)

export const updateCourse = async (id, data) =>
  api.put(`${URL}/course/${id}`, data)

export const updateAssignment = async (cid, aid, data, sendNotif = 'false') =>
  api.put(`${URL}/course/${cid}/assignment/${aid}/${sendNotif}`, data)

export const deleteAssignment = async (cid, aid) =>
  api.delete(`${URL}/course/${cid}/assignment/${aid}`)

export const publishAssignment = async (cid, aid) =>
  api.get(`${URL}/course/${cid}/assignment/${aid}/publish`)

export const setExercisesInAssignment = async (cid, aid, body) =>
  api.post(`${URL}/course/${cid}/assignment/${aid}/exercise`, body)

export const inviteStudentsToCourse = async (cid, body) =>
  api.post(`${URL}/course/${cid}/invite`, body)

export const responseToCourseInvitation = async (cid, response) =>
  api.get(`${URL}/course/${cid}/invite/${response}`)

export const sendMidiLogs = async body =>
  api.post(`${URL}/user/midi-logs`, body)

export const completeAssignment = async (courseID, assignmentID, data) =>
  api.post(
    `${URL}/course/${courseID}/assignment/${assignmentID}/complete`,
    data
  )

export const sendVerificationEmail = async address =>
  api.get(`${URL}/user/send-verification`)

export const sendResetPasswordEmail = async address =>
  api.post(`${URL}/user/reset-password`, { email: address })

export const resetPassword = async body =>
  api.post(`${URL}/user/reset-password/new`, body)

export const newSubscription = async data =>
  api.post(`${URL}/user/new-subscription`, data)

export const getSubscription = async subid =>
  api.get(`${URL}/user/subscription/${subid}`)

export const getTransections = async () =>
  api.get(`${URL}/user/transaction-history`)

export const cancelSubscription = async () =>
  api.get(`${URL}/user/cancel-subscription`)

export const getSubscriptionPlans = async () =>
  api.get(`${URL}/user/subscription-plans`)

export const reportIssue = async body =>
  api.post(`${URL}/user/report-issue`, body)

export const resendInvitation = async (courseID, email) =>
  api.get(`${URL}/course/${courseID}/resend-invitation/${email}`)
