import pullQuestion from '../services/hat-algorithm'

const getInitialState = () => {
  return {
    bucket: [],
    history: [],
    solved: 0,
    current: {
      exercise_id: '',
      preset: 0,
      key: 'c',
      soprano: 0,
      data: {
        bars: 1,
        midi: [],
        timestamps: [0],
        type: ['w'],
        time: [4, 4],
        mode: 'major',
      },
      figuredBass: false,
      steps: [],
    },
  }
}

const CONTENT = (state = getInitialState(), action) => {
  const temp = { ...state }
  switch (action.type) {
    case 'PULL_QUESTION':
      temp.bucket.push(action.payload.bucket)
      temp.current = {
        ...state.current,
        ...action.payload,
      }
      return { ...temp }

    case 'SET_TRANSPOSED_NOTES':
      temp.current.transposed = action.payload
      return { ...temp }

    case 'SOLVE_EXERCISE':
      return { ...temp, solved: temp.solved + 1 }

    case 'CLEAR_BUCKET':
      return { ...temp, bucket: [] }

    case 'RESET_CONTENT':
      return getInitialState()

    case 'SET_PRESET_SHOW_ANSWER':
      return { ...temp, current: { ...temp.current, preset: 2 } }

    default:
      return state
  }
}

export const setQuestion = (dispatch, assignment, library, bucket) => {
  const question = data => ({ type: 'PULL_QUESTION', payload: data })

  if (bucket.length === assignment.settings.totalNumOfQuestions - 1)
    dispatch({ type: 'CLEAR_BUCKET' })

  const newQuestion = pullQuestion(
    library,
    assignment,
    bucket.length === assignment.settings.totalNumOfQuestions - 1 ? [] : bucket
  )

  return dispatch(question(newQuestion))
}

// content
export const solveExercise = () => ({
  type: 'SOLVE_EXERCISE',
})

export const resetContent = () => ({
  type: 'RESET_CONTENT',
})

export const setTransposedNotes = notes => ({
  type: 'SET_TRANSPOSED_NOTES',
  payload: notes,
})

export const setPresetShowAnswer = () => ({
  type: 'SET_PRESET_SHOW_ANSWER',
})

export default CONTENT
