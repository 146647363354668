import axios from 'axios'
const api = axios.create({ withCredentials: true })
const url = process.env.REACT_APP_API + '/exercise'

const LIBRARY = (state = [], action) => {
  switch (action.type) {
    case 'FETCH_LIBRARY':
      return action.payload

    default:
      return state
  }
}

export const fetchLibrary = async dispatch => {
  const { data } = await api.get(url)
  return dispatch({
    type: 'FETCH_LIBRARY',
    payload: data,
  })
}

export const fetchAssignmentExercises = async (dispatch, assignmentID) => {
  const { data } = await api.get(`${url}?assignmentID=${assignmentID}`)
  return dispatch({
    type: 'FETCH_LIBRARY',
    payload: data,
  })
}

export default LIBRARY
