const MODALS = (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_MODAL':
      return { ...state, [action.payload[0]]: true, back: action.payload[1] }

    case 'HIDE_MODAL':
      return { ...state, [action.payload]: false, back: false }

    default:
      return state
  }
}

export const showModal = (dispatch, modal, back = false) =>
  dispatch({ type: 'SHOW_MODAL', payload: [modal, back] })

export const hideModal = (dispatch, modal) =>
  dispatch({ type: 'HIDE_MODAL', payload: modal })

export default MODALS
