import React from 'react'
import ReactDOM from 'react-dom'
import './sass/index.scss'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import reducer from './redux'
import { hotjar } from 'react-hotjar'
import App from './App'

const store = createStore(
  reducer /* preloadedState, */,
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 10 })
    : undefined
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

if (process.env.REACT_APP_USE_HOTJAR.trim() === 'true')
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_ID,
    process.env.REACT_APP_HOTJAR_SV
  )

export default store
