import React, { useRef, useState } from 'react'
import { Form, FloatingLabel, Button } from 'react-bootstrap'

import Terms from './Terms'
import VerificationMessage from './VerificationMessage'
import Radio from '../Shared/Global/Radio'
import Checkbox from '../Shared/Global/Checkbox'
import { signUp } from '../../services/apiCalls'

export default function Signup({ emailQuery }) {
  const [validated, setValidated] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [isTeacher, setIsTeacher] = useState(!emailQuery)
  const [veriMsg, setVeriMsg] = useState(false)
  const [terms, setTerms] = useState(false)
  const [emailErr, setEmailErr] = useState('')
  const passErr = useRef()

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    const form = e.currentTarget
    if (form.elements['password'].validity.patternMismatch)
      passErr.current.innerHTML =
        'Password should be at least 6 characters long with no spaces'
    setValidated(true)
    if (!form.checkValidity()) return null
    const data = [...form.elements]
      .filter(input => input.name)
      .reduce((obj, input) => {
        const value =
          input.type === 'radio' && !input.checked ? false : input.value
        if (!value) return obj
        return { ...obj, [input.name]: value }
      }, {})
    setValidated(false)
    signUp(data)
      .then(() => setVeriMsg(true))
      .catch(err => {
        if (err.response.status === 401) setEmailErr(err.response.data)
      })
  }

  const handleCloseVeriMessage = () => {
    setVeriMsg(false)
    window.location.replace('/?signin=true')
  }

  return (
    <>
      <Terms show={showTerms} onHide={() => setShowTerms(false)} />
      <VerificationMessage show={veriMsg} onHide={handleCloseVeriMessage} />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <div className="mb-2">
          <Radio
            name="role"
            label="I am a teacher"
            checked={isTeacher}
            onChange={() => setIsTeacher(!isTeacher)}
            value="teacher"
            disabled={emailQuery}
          />
          <Radio
            name="role"
            label="I am a student"
            checked={!isTeacher}
            onChange={() => setIsTeacher(!isTeacher)}
            value="student"
            disabled={emailQuery}
          />
        </div>

        <div className="d-flex mb-2">
          <FloatingLabel label="First name" className="me-1">
            <Form.Control placeholder="First name" name="firstName" required />
          </FloatingLabel>

          <FloatingLabel label="Last Name" className="ms-1">
            <Form.Control placeholder="Last name" name="lastName" required />
          </FloatingLabel>
        </div>

        <FloatingLabel label="Email address" className="mb-2">
          <Form.Control
            type="email"
            placeholder="Email address"
            name="email"
            required
            isInvalid={emailErr}
            defaultValue={emailQuery || ''}
            disabled={emailQuery}
          />
          <Form.Control.Feedback type="invalid">
            {emailErr}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel label="Password">
          <Form.Control
            type="password"
            name="password"
            placeholder="Password"
            pattern="^\S{6,}$"
            required
          />
          <Form.Control.Feedback type="invalid" ref={passErr} />
        </FloatingLabel>

        <div className="d-flex mt-2">
          <Checkbox
            type="checkbox"
            name="terms"
            checked={terms}
            onChange={() => setTerms(!terms)}
          />

          <p key="tac" className={!terms && validated ? 'text-danger' : ''}>
            I agree to the
            <button
              type="button"
              className="text-primary border-0 bg-white"
              onClick={() => setShowTerms(true)}
            >
              terms &amp; conditions
            </button>
          </p>
        </div>

        <Button type="submit" className="w-100 mb-3 text-white">
          Signup
        </Button>
      </Form>
    </>
  )
}
