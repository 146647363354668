const init = {
  time: 0,
  mistakes: [],
  passes: 0,
}

const SCORE = (state = { ...init }, action) => {
  switch (action.type) {
    case 'SET_SCORE_VALUE':
      return { ...state, [action.payload.key]: action.payload.value }
    case 'CLEAR_SCORE':
      return init
    default:
      return state
  }
}

export default SCORE

export const setScoreValue = (key, value) => ({
  type: 'SET_SCORE_VALUE',
  payload: { key, value },
})

export const clearScore = () => ({ type: 'CLEAR_SCORE' })
