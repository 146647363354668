import React, { useState } from 'react'
import { Form, FloatingLabel, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

import { signin } from '../../redux/user.state'
import SendResetPassword from './SendResetPassword'

export default function SignIn() {
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)
  const [forgotPass, setForgotPass] = useState(false)
  const [serverErr, setServerErr] = useState('')

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    const form = e.currentTarget
    setValidated(true)
    if (!form.checkValidity()) return null
    setValidated(false)
    const data = [...form.elements]
      .filter(input => input.name)
      .reduce((obj, input) => ({ ...obj, [input.name]: input.value }), {})
    signin(dispatch, data).catch(err => setServerErr(err.response.data))
  }

  return (
    <>
      <SendResetPassword
        show={forgotPass}
        handleClose={() => setForgotPass(false)}
      />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <FloatingLabel label="Email address" className="mb-2">
          <Form.Control
            type="email"
            placeholder="Email address"
            name="email"
            required
            isInvalid={serverErr}
          />
          <Form.Control.Feedback type="invalid">
            {serverErr}
          </Form.Control.Feedback>
        </FloatingLabel>

        <FloatingLabel label="Password" className="mb-2">
          <Form.Control
            type="password"
            name="password"
            placeholder="Password"
            isInvalid={serverErr}
            required
          />
        </FloatingLabel>

        <Button type="submit" className="w-100 text-white">
          Sign In
        </Button>

        <button
          type="button"
          className="text-primary border-0 p-0 bg-white mb-2"
          style={{ textDecoration: 'none' }}
          onClick={() => setForgotPass(true)}
        >
          <small>forgot password?</small>
        </button>
      </Form>
    </>
  )
}
