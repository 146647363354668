import { VOICES } from './constants'

const claims = [
  {
    name: 'unmatched-notes',
    priority: 0,
    level: 'mistake',
    msg: () => 'Play the notes exactly as shown',
    steps: i => [i],
  },
  {
    name: 'voices-exceeded',
    priority: 0,
    level: 'mistake',
    msg: () => 'Too many voices, play this exercise in 4 parts harmony',
    steps: i => [i],
  },
  {
    name: 'wrong-chord',
    priority: 1,
    level: 'mistake',
    msg: () => 'Wrong chord',
    steps: i => [i],
  },
  {
    name: 'missing-x',
    priority: 1,
    level: 'mistake',
    msg: (_, voice) => `The ${voice} of the chord is missing`,
    steps: i => [i],
  },
  {
    name: 'raise-3rd',
    priority: 1,
    level: 'mistake',
    msg: () => `The 3rd of the chord should be raised`,
    steps: i => [i],
  },
  {
    name: 'wrong-inversion',
    priority: 2,
    level: 'mistake',
    msg: () => 'Wrong inversion',
    steps: i => [i],
  },
  {
    name: 'soprano-line',
    priority: 2,
    level: 'mistake',
    msg: () => 'Play the soprano line as shown',
    steps: i => [i],
  },
  {
    name: 'bass-line',
    priority: 2,
    level: 'mistake',
    msg: () => 'Play the bass line as shown',
    steps: i => [i],
  },
  {
    name: 'bass-leap',
    priority: 3,
    level: 'mistake',
    msg: () => 'Large leap in Bass line',
    steps: i => [i - 1, i],
  },
  {
    name: 'bass-out-of-range',
    priority: 3,
    level: 'warning',
    msg: (_, adj) => `Bass is very ${adj}`,
    steps: i => [i - 1, i],
  },
  {
    name: 'missing-5th',
    priority: 3,
    level: 'mistake',
    msg: () => 'Missing 5th',
    steps: i => [i],
  },
  {
    name: 'bad-doubling',
    priority: 3,
    level: 'mistake',
    msg: () => 'Bad doubling',
    steps: i => [i],
  },
  {
    name: 'bad-doubling-dominant',
    priority: 3,
    level: 'mistake',
    msg: (_, name) => `Bad doubling of the ${name}`,
    steps: i => [i],
  },
  {
    name: 'bad-tonic-resolution',
    priority: 3,
    level: 'mistake',
    msg: () => 'Wrong resolution',
    steps: i => [i - 1, i],
  },
  {
    name: 'disonance-enter-dominant',
    priority: 4,
    level: 'warning',
    msg: (_, isSevenInPrev) =>
      isSevenInPrev
        ? 'The 7th should be kept stationary from the previous chord'
        : 'The 7th should be derrived from a descending 2nd',
    steps: i => [i - 1, i],
  },
  {
    name: 'parallels',
    priority: 4,
    level: 'mistake',
    msg: (voices, interval) =>
      `Parallel ${interval === 7 ? '5th' : '8ve'} between ${
        VOICES[voices[0]]
      } and ${VOICES[voices[1]]}`,
    steps: i => [i - 1, i],
  },
  {
    name: 'augmented-2nd',
    priority: 4,
    level: 'mistake',
    msg: voices => `Augmented 2nd in the ${VOICES[voices[0]]}`,
    steps: i => [i - 1, i],
  },
  {
    name: 'voice-leap',
    priority: 5,
    level: 'mistake',
    msg: voices => `There is a large leap in the ${VOICES[voices[0]]}`,
    steps: i => [i - 1, i],
  },
  {
    name: 'open-position',
    priority: 6,
    level: 'warning',
    msg: () =>
      'Open-space voicing should be avoided when possible in keyboard style',
    steps: i => [i],
  },
  {
    name: 'hidden-consecutives',
    priority: 7,
    level: 'mistake',
    msg: (_, interval) => `Hidden ${interval === 7 ? '5th' : '8ve'}`,
    steps: i => [i - 1, i],
  },

  {
    name: 'doubling-3rd-in-1st-inversion',
    priority: 0,
    level: 'warning',
    msg: () => 'It is more common to avoid the doubling of the 3rd in I6',
    steps: i => [i],
  },
  {
    name: 'large-leap',
    priority: 0,
    level: 'warning',
    msg: () => 'Large leaps in voices, there is another shorter way',
    steps: i => [i - 1, i],
  },
  {
    name: 'large-leap-in-voice',
    priority: 0,
    level: 'warning',
    msg: voices => `The ${VOICES[voices[0]]} voice can move in a shorter way`,
    steps: i => [i - 1, i],
  },
]

// String -> Number -> [Number] -> Any => Claim
const claim = (name, steps, voices, ...rest) => {
  const c = claims.find(x => x.name === name)
  if (!c) return null
  const i = c.steps(steps)
  const msg = c.msg(voices, ...rest)
  return {
    ...c,
    steps: i,
    msg,
    voices,
  }
}

export default claim

const claimId = c => c.name + c.voices.join('') + c.steps.join('')

export const claimSet = cs =>
  cs.reduce((y, x) => {
    if (!y.some(c => claimId(x) === claimId(c))) return [...y, x]
    return y
  }, [])
