import React, { useState } from 'react'
import { Row, Container, Form, Button, FloatingLabel } from 'react-bootstrap'
import { BsFillLockFill } from 'react-icons/bs'

import Logo from '../Shared/Global/Logo'
import { resetPassword } from '../../services/apiCalls'

export default function ResetPassword({ token }) {
  const [validated, setValidated] = useState(false)
  const [serverErr, setServerErr] = useState('')

  const handleSubmit = e => {
    e.stopPropagation()
    e.preventDefault()
    const form = e.currentTarget
    setValidated(true)
    if (!form.checkValidity()) return null
    setValidated(false)
    const data = [...form.elements]
      .filter(input => input.name)
      .reduce((obj, input) => ({ ...obj, [input.name]: input.value }), {})
    resetPassword({ ...data, token })
      .then(() =>
        window.location.replace(window.location.origin + '?signin=true')
      )
      .catch(() => setServerErr('Something went wrong, please try again later'))
  }

  return (
    <Container fluid>
      <Row className="vh-100 bg-light justify-content-center align-items-center flex-column">
        <div
          className="bg-white col-12 col-sm-4 rounded shadow-sm p-4 d-flex flex-column align-items-center"
          style={{ height: 'fit-content' }}
        >
          <Logo
            style={{ fill: '#01b6d5', maxWidth: '150px' }}
            className="mb-2"
            onClick={() => window.location.replace(window.location.origin)}
          />
          <p className="text-dark">
            <BsFillLockFill className="mb-1 mr-2" />
            Reset Password
          </p>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Text>Please enter your new password:</Form.Text>
            <FloatingLabel label="New password" className="mb-2">
              <Form.Control
                type="password"
                name="password"
                placeholder="New password"
                pattern="^\S{6,}$"
                isInvalid={serverErr}
                required
              />
              <Form.Control.Feedback type="invalid">
                {serverErr}
              </Form.Control.Feedback>
            </FloatingLabel>

            <Button type="submit" className="w-100 text-white">
              Update Password
            </Button>
          </Form>
        </div>
      </Row>
    </Container>
  )
}
