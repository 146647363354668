import evalScaler from '../harmonic-analysis/auto-range'
import { keyCharToInt, transpose } from '../harmonic-analysis/base-functions'
import { setBassScaler } from '../harmonic-analysis/bassline-leaps'
import findChord, { cad64 } from '../harmonic-analysis/findChord'

// building blocks
export const rollDice = sets => sets[Math.floor(Math.random() * sets.length)]

export const addKey = (key, set) => ({ [key]: rollDice(set) })

export const generateSettings = sets =>
  Object.keys(sets).reduce((y, x) => ({ ...y, ...addKey(x, sets[x]) }), {})

// generating a single question
export const generateQuestion = (exs, sets) => {
  const { _id } = rollDice(exs)
  const settings = generateSettings(sets)
  return { exercise: _id, ...settings }
}

// generating a unique id from a question
export const uid = question =>
  Object.keys(question).reduce((y, x) => `${y}${question[x]}`, '')

export const checkBucket = (id, bucket) => bucket.some(n => n === id)

// final solution
export default function pullQuestion(library, assignment, bucket) {
  const exercises = library
  const { settings } = assignment

  const question = generateQuestion(exercises, settings)
  const { data } = exercises.find(ex => ex._id === question.exercise)

  const id = uid({
    id: question.exercise,
    preset: question.presets,
    keys: question.keys,
    soprano: question.positions,
  })

  const wasAlready = checkBucket(id, bucket)
  if (wasAlready) return pullQuestion(library, assignment, bucket)

  const p =
    question.positions > data.midi.length - 1
      ? Math.floor(Math.random() * data.midi.length)
      : question.positions

  const steps = data.midi[p][
    Math.floor(Math.random() * data.midi[p].length)
  ].map(s =>
    findChord(
      s.map(n => transpose(n, keyCharToInt(question.keys))),
      keyCharToInt(question.keys),
      data.mode,
      settings.figuredBass
    )
  )

  const scaler = evalScaler(steps[0].raw.slice(1))

  const rangedSteps = steps.map(s => {
    const raw = s.raw.map(n => n + scaler)
    raw[0] += setBassScaler(raw[0])
    return { ...s, raw }
  })

  rangedSteps.forEach((step, i) => {
    if (i < rangedSteps.length - 1)
      step.figure = cad64(step.figure, rangedSteps[i + 1].figure)
  })

  return {
    bucket: id,
    exercise_id: question.exercise,
    preset: question.presets,
    key: question.keys,
    soprano: question.positions,
    data,
    steps: rangedSteps,
  }
}
