const [min, max] = [60, 81]

const isBelow = x => x < min

const isAbove = x => x > max

const scalerType = step => {
  if (isBelow(Math.min(...step))) return 12
  if (isAbove(Math.max(...step))) return -12
  return 0
}

const countOutsiders = (step, scl) => {
  if (scl === 0) return 0
  const cond = scl > 0 ? isAbove : isBelow
  return step.filter(x => cond(x)).length
}

const evalScaler = (step, prev = 0) => {
  const s = scalerType(step)
  const c = countOutsiders(step, s)
  if (c === prev || c === 0) return s
  return evalScaler(step, c)
}

export default evalScaler
